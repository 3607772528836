var locale = {
    "nb-NO": {
        "addToBasketButtonTextPrints": "Bestill",
        "addToBasketButtonText": "Legg i handlekurv",
        "addToBasketButtonTextClicked": "Fortsett til handlekurven",
        "priceDefaultText": "Pris",
        "pricePrintsDefaultText": "Fra",
        "discountDefaultText": "Bruk kode",
        "moreInfoDefaultText": "Forhåndsvisning",
        "moreInfoTryButtonText": "Endre bilde",
        "bottomTextMoreInfo": "Tilpass",
        "bottomTextChangePhoto": "Bytt bilde",
        "bottomTextShowLargerImage": "Vis større bilde",
        "addedToBasketOverlay": "Lagt i handlekurv",
        "popupDiscountTextPart1": "kopier kode",
        "popupDiscountTextPart2": "og lim inn i handlekurven",
        "addToBasketButtonTextOutOfStock": "Utsolgt",
        "discountRibbonText": "Rabatt",
        "moreInfoProductSizesText": "Velg størrelse",
        "chooseVoucher": "Velg ditt gavekort nedenfor",
        "photobookDiscountText": "Eksklusiv Rabatt",
        "discount": "Rabatt",
        "viewAllProducts": "Vis alle produkter",
        "selectGiftVoucher": "Velg et gavekort",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% rabatt på kopi av fotobok" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% rabatt på kopp nummer to" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% rabatt på lerret nummer to" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% rabatt på fotoplakat nummer to" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% rabatt på skumplate nummer to" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% rabatt på aluminiumsplate nummer to" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% rabatt på plexiglassplate nummer to" 
            }
        },                    
        "secondCopyPhotobookGiftIdea": "Gavetips",
        "secondCopyPhotobookGiftIdeaDescription": "Bestill ekstra kopier av fotoboken og gi bort til venner og familie!",
        "discountedProductGiftIdeaDescription-Canvas": "Bestill en ekstra kopi av lerretet til dine kjære!",
        "discountedProductGiftIdeaDescription-Poster": "Bestill en ekstra kopi av plakaten til dine kjære!",
        "discountedProductGiftIdeaDescription-Aluminium": "Bestill en ekstra kopi av aluminiumsplaten til dine kjære!",
        "discountedProductGiftIdeaDescription-Forex": "Bestill en ekstra kopi av skumplaten til dine kjære!",
        "discountedProductGiftIdeaDescription-Perspex": "Bestill en ekstra kopi av plexiglassplaten til dine kjære!",
        "discountedProductGiftIdeaDescription-Mug": "Bestill en ekstra kopi av koppen til dine kjære!",
        "copyof": "Kopi av",
        "editPhoto": "Rediger bilde",
        "onlyVisibleToYou": "Bare synlig for deg",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Nå kan du enkelt fremkalle bildene fra fotoboken din",
        "printsLink": "Se dine bilder"
    },
    "en-GB": {
        "addToBasketButtonTextPrints": "Go to editor",
        "addToBasketButtonText": "Add to basket",
        "addToBasketButtonTextClicked": "Continue to cart",
        "priceDefaultText": "Price",
        "pricePrintsDefaultText": "From",
        "discountDefaultText": "Use code",
        "moreInfoDefaultText": "Large view",
        "moreInfoTryButtonText": "Try different photos",
        "bottomTextMoreInfo": "Customise",
        "bottomTextChangePhoto": "Change photo",
        "bottomTextShowLargerImage": "Show larger image",
        "addedToBasketOverlay": "Added to basket",
        "popupDiscountTextPart1": "use code",
        "popupDiscountTextPart2": "to receive the discount",
        "addToBasketButtonTextOutOfStock": "Out of stock",
        "discountRibbonText": "Save",
        "moreInfoProductSizesText": "Select size",
        "chooseVoucher": "Choose your voucher below",
        "photobookDiscountText": "Exclusive Discount",
        "discount": "Discount",
        "viewAllProducts": "View all products",
        "selectGiftVoucher": "Select a gift voucher",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% OFF 2nd PHOTO BOOK" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% off 2nd mug" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% off 2nd canvas print" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% off 2nd poster print" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% off 2nd mounted print" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% off 2nd aluminium print" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% off 2nd acrylic print" 
            }
        }, 
        "secondCopyPhotobookGiftIdea": "Gift idea",
        "secondCopyPhotobookGiftIdeaDescription": "Get a copy of your photo book for your loved ones!",
        "discountedProductGiftIdeaDescription-Canvas": "Get a copy of your canvas print for your loved ones!",
        "discountedProductGiftIdeaDescription-Poster": "Get a copy of your poster print for your loved ones!",
        "discountedProductGiftIdeaDescription-Aluminium": "Get a copy of your aluminium print for your loved ones!",
        "discountedProductGiftIdeaDescription-Forex": "Get a copy of your mounted print for your loved ones!",
        "discountedProductGiftIdeaDescription-Perspex": "Get a copy of your acrylic print for your loved ones!",
        "discountedProductGiftIdeaDescription-Mug": "Get a copy of your mug for your loved ones!",        
        "copyof": "Copy of",
        "editPhoto": "Edit photo", //needs translation
        "onlyVisibleToYou": "Only visible to you",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Your pictures are available as photo prints too",
        "printsLink": "View photo prints"
    },
    "en-IE": {
        "addToBasketButtonTextPrints": "Go to editor",
        "addToBasketButtonText": "Add to basket",
        "addToBasketButtonTextClicked": "Continue to cart",
        "priceDefaultText": "Price",
        "pricePrintsDefaultText": "From",
        "discountDefaultText": "Use code",
        "moreInfoDefaultText": "Large view",
        "moreInfoTryButtonText": "Try different photos",
        "bottomTextMoreInfo": "Customise",
        "bottomTextChangePhoto": "Change photo",
        "bottomTextShowLargerImage": "Show larger image",
        "addedToBasketOverlay": "Added to basket",
        "popupDiscountTextPart1": "use code",
        "popupDiscountTextPart2": "to receive the discount",
        "addToBasketButtonTextOutOfStock": "Out of stock",
        "discountRibbonText": "Save",
        "moreInfoProductSizesText": "Select size",
        "chooseVoucher": "Choose your voucher below",
        "photobookDiscountText": "Exclusive Discount",
        "discount": "Discount",
        "viewAllProducts": "View all products",
        "selectGiftVoucher": "Select a gift voucher",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% OFF 2nd PHOTO BOOK" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% off 2nd mug" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% off 2nd canvas print" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% off 2nd poster print" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% off 2nd mounted print" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% off 2nd aluminium print" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% off 2nd acrylic print" 
            }
        }, 
        "secondCopyPhotobookGiftIdea": "Gift idea",
        "secondCopyPhotobookGiftIdeaDescription": "Get a copy of your photo book for your loved ones!",
        "discountedProductGiftIdeaDescription-Canvas": "Get a copy of your canvas print for your loved ones!",
        "discountedProductGiftIdeaDescription-Poster": "Get a copy of your poster print for your loved ones!",
        "discountedProductGiftIdeaDescription-Aluminium": "Get a copy of your aluminium print for your loved ones!",
        "discountedProductGiftIdeaDescription-Forex": "Get a copy of your mounted print for your loved ones!",
        "discountedProductGiftIdeaDescription-Perspex": "Get a copy of your acrylic print for your loved ones!",
        "discountedProductGiftIdeaDescription-Mug": "Get a copy of your mug for your loved ones!",        
        "copyof": "Copy of",
        "editPhoto": "Edit photo", //needs translation
        "onlyVisibleToYou": "Only visible to you",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Your pictures are available as photo prints too",
        "printsLink": "View photo prints"
    },
    "de-DE": {
        "addToBasketButtonTextPrints": "",
        "addToBasketButtonText": "In den Warenkorb legen",
        "addToBasketButtonTextClicked": "Weiter zum Warenkorb",
        "priceDefaultText": "Preis",
        "pricePrintsDefaultText": "",
        "discountDefaultText": "",
        "moreInfoDefaultText": "Vorschau",
        "moreInfoTryButtonText": "Teste verschiedene Fotos",
        "bottomTextMoreInfo": "Anpassen",
        "bottomTextChangePhoto": "",
        "bottomTextShowLargerImage": "",
        "addedToBasketOverlay": "In den Warenkorb gelegt",
        "popupDiscountTextPart1": "",
        "popupDiscountTextPart2": "",
        "addToBasketButtonTextOutOfStock": "Out of stock",
        "discountRibbonText": "Rabatt",
        "moreInfoProductSizesText": "Verfügbare Größe",
        "chooseVoucher": "Wähle unten deinen Gutschein",
        "photobookDiscountText": "Exklusiver Rabatt",
        "discount": "Rabatt",
        "viewAllProducts": "Alle Produkte anzeigen",
        "selectGiftVoucher": "Gutschein auswählen",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf das zweite Fotobuch" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweite Tasse" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweite Leinwand" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweites Poster" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweites Fotoboard" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweites Foto auf Aluminium" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweites Acrylglas-Foto" 
            }
        },         
        "secondCopyPhotobookGiftIdea": "Geschenkideen",
        "secondCopyPhotobookGiftIdeaDescription": "Verschenke eine Kopie deines Fotobuchs an Freunde und Familie",
        "discountedProductGiftIdeaDescription-Canvas": "Hol dir ein zweites Exemplar deiner Leinwand für deine Lieben!",
        "discountedProductGiftIdeaDescription-Poster": "Hol dir ein zweites Exemplar deines Posters für deine Lieben!",
        "discountedProductGiftIdeaDescription-Aluminium": "Hol dir ein zweites Exemplar deines Fotos auf Aluminium für deine Lieben!",
        "discountedProductGiftIdeaDescription-Forex": "Hol dir ein zweites Exemplar deines Fotoboards für deine Lieben!",
        "discountedProductGiftIdeaDescription-Perspex": "Hol dir ein zweites Exemplar deines Acrylglas-Fotos für deine Lieben!",
        "discountedProductGiftIdeaDescription-Mug": "Hol dir ein zweites Exemplar deiner Tasse für deine Lieben!",        
        "copyof": "Kopie von",
        "editPhoto": "Edit photo", //needs translation
        "onlyVisibleToYou": "Nur für dich sichtbar",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Du kannst auch Fotoabzüge von deinen Bildern bestellen",
        "printsLink": "Fotoabzüge anschauen"
    },
    "de-AT": {
        "addToBasketButtonTextPrints": "",
        "addToBasketButtonText": "In den Warenkorb legen",
        "addToBasketButtonTextClicked": "Weiter zum Warenkorb",
        "priceDefaultText": "Preis",
        "pricePrintsDefaultText": "",
        "discountDefaultText": "",
        "moreInfoDefaultText": "Vorschau",
        "moreInfoTryButtonText": "Teste verschiedene Fotos",
        "bottomTextMoreInfo": "Anpassen",
        "bottomTextChangePhoto": "",
        "bottomTextShowLargerImage": "",
        "addedToBasketOverlay": "In den Warenkorb gelegt",
        "popupDiscountTextPart1": "",
        "popupDiscountTextPart2": "",
        "addToBasketButtonTextOutOfStock": "Out of stock",
        "discountRibbonText": "Rabatt",
        "moreInfoProductSizesText": "Verfügbare Größe",
        "chooseVoucher": "Wähle unten deinen Gutschein",
        "photobookDiscountText": "Exklusiver Rabatt",
        "discount": "Rabatt",
        "viewAllProducts": "Alle Produkte anzeigen",
        "selectGiftVoucher": "Gutschein auswählen",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf das zweite Fotobuch" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweite Tasse" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweite Leinwand" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweites Poster" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweites Fotoboard" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweites Foto auf Aluminium" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% Rabatt auf zweites Acrylglas-Foto" 
            }
        },         
        "secondCopyPhotobookGiftIdea": "Geschenkideen",
        "secondCopyPhotobookGiftIdeaDescription": "Verschenke eine Kopie deines Fotobuchs an Freunde und Familie",
        "discountedProductGiftIdeaDescription-Canvas": "Hol dir ein zweites Exemplar deiner Leinwand für deine Lieben!",
        "discountedProductGiftIdeaDescription-Poster": "Hol dir ein zweites Exemplar deines Posters für deine Lieben!",
        "discountedProductGiftIdeaDescription-Aluminium": "Hol dir ein zweites Exemplar deines Fotos auf Aluminium für deine Lieben!",
        "discountedProductGiftIdeaDescription-Forex": "Hol dir ein zweites Exemplar deines Fotoboards für deine Lieben!",
        "discountedProductGiftIdeaDescription-Perspex": "Hol dir ein zweites Exemplar deines Acrylglas-Fotos für deine Lieben!",
        "discountedProductGiftIdeaDescription-Mug": "Hol dir ein zweites Exemplar deiner Tasse für deine Lieben!",     
        "copyof": "Kopie von",
        "editPhoto": "Edit photo", //needs translation
        "onlyVisibleToYou": "Nur für dich sichtbar",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Du kannst auch Fotoabzüge von deinen Bildern bestellen",
        "printsLink": "Fotoabzüge anschauen"
    },
    "nl-NL": {
        "addToBasketButtonTextPrints": "",
        "addToBasketButtonText": "Toevoegen aan winkelmandje",
        "addToBasketButtonTextClicked": "Naar winkelmandje",
        "priceDefaultText": "prijs",
        "pricePrintsDefaultText": "",
        "discountDefaultText": "",
        "moreInfoDefaultText": "Voorbeeld",
        "moreInfoTryButtonText": "Probeer verschillende foto's",
        "bottomTextMoreInfo": "Aanpassen",
        "bottomTextChangePhoto": "",
        "bottomTextShowLargerImage": "",
        "addedToBasketOverlay": "Aan winkelmandje toegevoegd ",
        "popupDiscountTextPart1": "",
        "popupDiscountTextPart2": "",
        "addToBasketButtonTextOutOfStock": "Out of stock",
        "discountRibbonText": "Korting",
        "moreInfoProductSizesText": "Kies je formaat",
        "chooseVoucher": "Kies hieronder je voucher",
        "discount": "Korting",
        "photobookDiscountText": "Exclusieve Korting",
        "viewAllProducts": "Bekijk alle producten",
        "selectGiftVoucher": "Selecteer een voucher",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede fotoboek" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede mok" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede canvas wanddecoratie" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede poster wanddecoratie" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede forex wanddecoratie" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede aluminium wanddecoratie" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede plexiglas wanddecoratie" 
            }
        },         
        "secondCopyPhotobookGiftIdea": "Cadeau-idee:",
        "secondCopyPhotobookGiftIdeaDescription": "Geef een kopie van jouw fotoboek aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Canvas": "Geef een kopie van je canvas wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Poster": "Geef een kopie van je poster wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Aluminium": "Geef een kopie van je aluminium wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Forex": "Geef een kopie van je forex wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Perspex": "Geef een kopie van je plexiglas wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Mug": "Geef een kopie van je mok aan familie of vrienden!",        
        "copyof": "Exemplaar van",
        "editPhoto": "Edit photo", //needs translation
        "onlyVisibleToYou": "Alleen zichtbaar voor jou",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Je kunt ook fotoafdrukken van je foto's bestellen",
        "printsLink": "Fotoafdrukken bekijken"
    },
    "nl-BE": {
        "addToBasketButtonTextPrints": "",
        "addToBasketButtonText": "Toevoegen aan winkelmandje",
        "addToBasketButtonTextClicked": "Naar winkelmandje",
        "priceDefaultText": "prijs",
        "pricePrintsDefaultText": "",
        "discountDefaultText": "",
        "moreInfoDefaultText": "Voorbeeld",
        "moreInfoTryButtonText": "Probeer verschillende foto's",
        "bottomTextMoreInfo": "Aanpassen",
        "bottomTextChangePhoto": "",
        "bottomTextShowLargerImage": "",
        "addedToBasketOverlay": "Aan winkelmandje toegevoegd ",
        "popupDiscountTextPart1": "",
        "popupDiscountTextPart2": "",
        "addToBasketButtonTextOutOfStock": "Out of stock",
        "discountRibbonText": "Korting",
        "moreInfoProductSizesText": "Beschikbare maten",
        "chooseVoucher": "Kies hieronder je voucher",
        "discount": "Korting",
        "photobookDiscountText": "Exclusieve Korting",
        "viewAllProducts": "Bekijk alle producten",
        "selectGiftVoucher": "Selecteer een voucher",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede fotoboek" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede mok" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede canvas wanddecoratie" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede poster wanddecoratie" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede forex wanddecoratie" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede aluminium wanddecoratie" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% korting op je tweede plexiglas wanddecoratie" 
            }
        },         
        "secondCopyPhotobookGiftIdea": "Cadeau-idee:",
        "secondCopyPhotobookGiftIdeaDescription": "Geef een kopie van jouw fotoboek aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Canvas": "Geef een kopie van je canvas wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Poster": "Geef een kopie van je poster wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Aluminium": "Geef een kopie van je aluminium wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Forex": "Geef een kopie van je forex wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Perspex": "Geef een kopie van je plexiglas wanddecoratie aan familie of vrienden!",
        "discountedProductGiftIdeaDescription-Mug": "Geef een kopie van je mok aan familie of vrienden!",     
        "copyof": "Exemplaar van",
        "editPhoto": "Edit photo", //needs translation
        "onlyVisibleToYou": "Alleen zichtbaar voor jou",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Je kunt ook fotoafdrukken van je foto's bestellen",
        "printsLink": "Fotoafdrukken bekijken"
    },
    "sv-SE": {
        "addToBasketButtonTextPrints": "Beställ",
        "addToBasketButtonText": "Lägg i kundvagnen",
        "addToBasketButtonTextClicked": "Fortsätt till kundvagnen",
        "priceDefaultText": "pris",
        "pricePrintsDefaultText": "från",
        "discountDefaultText": "Använd rabattkod",
        "moreInfoDefaultText": "Förhandsvisningen",
        "moreInfoTryButtonText": "Testa olika bilder",
        "bottomTextMoreInfo": "Skräddarsy",
        "bottomTextChangePhoto": "Ändra bild",
        "bottomTextShowLargerImage": "Visa större bild",
        "addedToBasketOverlay": "Tillagt i kundvagnen",
        "popupDiscountTextPart1": "Kopiera rabattkod",
        "popupDiscountTextPart2": "och klistra in i kundvagnen",
        "addToBasketButtonTextOutOfStock": "Slutsåld",
        "discountRibbonText": "Rabatt",
        "moreInfoProductSizesText": "Tillgängliga storlekar",
        "chooseVoucher": "Välj värdecheck nedan",
        "photobookDiscountText": "Exklusiv rabatt",
        "discount": "Rabatt",
        "viewAllProducts": "Visa alla produkter",
        "selectGiftVoucher": "Välj ett presentkort",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% rabatt på en extra kopia av din fotobok" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% rabatt på din andra mugg" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% rabatt på din andra Canvastavla" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% rabatt på din andra Fotoposter" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% rabatt på din andra Forextavla" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% rabatt på din andra Aluminiumtavla" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% rabatt på din andra Plexiglastavla" 
            }
        },         
        "secondCopyPhotobookGiftIdea": "Presenttips",
        "secondCopyPhotobookGiftIdeaDescription": "Beställ extra kopior av din fotobok och ge bort till nära och kära!",
        "discountedProductGiftIdeaDescription-Canvas": "Beställ en kopia av din canvastavla att ge bort i present!",
        "discountedProductGiftIdeaDescription-Poster": "Beställ en kopia av din fotoposter att ge bort i present!",
        "discountedProductGiftIdeaDescription-Aluminium": "Beställ en kopia av din aluminiumtavla att ge bort i present!",
        "discountedProductGiftIdeaDescription-Forex": "Beställ en kopia av din Forextavla att ge bort i present!",
        "discountedProductGiftIdeaDescription-Perspex": "Get a copy of your acrylic print for your loved ones!",
        "discountedProductGiftIdeaDescription-Mug": "Beställ en kopia av din Plexiglastavla att ge bort i present!",        
        "copyof": "kopia av",
        "editPhoto": "Edit photo", //needs translation
        "onlyVisibleToYou": "Endast synliga för dig",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Du kan även framkalla alla bilderna som du har använt i din fotobok",
        "printsLink": "Visa bilder"
    },
    "fr-FR": {
        "addToBasketButtonTextPrints": "J'édite",
        "addToBasketButtonText": "Ajouter au panier",
        "addToBasketButtonTextClicked": "Continuer au panier",
        "priceDefaultText": "prix",
        "pricePrintsDefaultText": "À partir de",
        "discountDefaultText": "Utiliser le code",
        "moreInfoDefaultText": "Aperçu",
        "moreInfoTryButtonText": "Essayer différentes photos",
        "bottomTextMoreInfo": "Personnaliser",
        "bottomTextChangePhoto": "Changer de photo",
        "bottomTextShowLargerImage": "Agrandir l'image",
        "addedToBasketOverlay": "Ajouté au panier",
        "popupDiscountTextPart1": "Utiliser le code",
        "popupDiscountTextPart2": "appliquer la promotion",
        "addToBasketButtonTextOutOfStock": "Out of stock",
        "discountRibbonText": "De Réduction",
        "moreInfoProductSizesText": "Tailles disponibles",
        "chooseVoucher": "Choisissez votre bon dès maintenant",
        "photobookDiscountText": "Réduction Exclusive",
        "discount": "Réduction",
        "viewAllProducts": "Voir tous les produits",
        "selectGiftVoucher": "Sélectionnez un bon",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% SUR LE 2ème LIVRE PHOTO" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% sur votre second mug" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% sur votre seconde toile photo" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% sur votre second poster photo" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% sur votre seconde photo sur PVC" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% sur votre seconde photo sur aluminium" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% sur votre seconde photo sur plexiglas" 
            }
        },         
        "secondCopyPhotobookGiftIdea": "Idée cadeau",
        "secondCopyPhotobookGiftIdeaDescription": "Commandez un autre exemplaire de votre livre photo pour vos proches !",
        "discountedProductGiftIdeaDescription-Canvas": "Commandez une seconde toile photo identique pour vos proches!",
        "discountedProductGiftIdeaDescription-Poster": "Commandez un second poster photo identique pour vos proches!",
        "discountedProductGiftIdeaDescription-Aluminium": "Commandez une seconde photo sur aluminium pour vos proches!",
        "discountedProductGiftIdeaDescription-Forex": "Commandez une seconde photo sur PVC pour vos proches!",
        "discountedProductGiftIdeaDescription-Perspex": "Commandez une seconde photo sur plexiglas pour vos proches!",
        "discountedProductGiftIdeaDescription-Mug": "Commandez un second mug identique pour vos proches!",        
        "copyof": "Copie de",
        "editPhoto": "Edit photo", //needs translation
        "onlyVisibleToYou": "Visible uniquement par vous",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Imprimez également vos photos sous forme de tirages ",
        "printsLink": "Voir mes tirages photo"
    },
    "fr-BE": {
        "addToBasketButtonTextPrints": "J'édite",
        "addToBasketButtonText": "Ajouter au panier",
        "addToBasketButtonTextClicked": "Continuer au panier",
        "priceDefaultText": "prix",
        "pricePrintsDefaultText": "À partir de",
        "discountDefaultText": "Utiliser le code",
        "moreInfoDefaultText": "Aperçu",
        "moreInfoTryButtonText": "Essayer différentes photos",
        "bottomTextMoreInfo": "Personnaliser",
        "bottomTextChangePhoto": "Changer de photo",
        "bottomTextShowLargerImage": "Agrandir l'image",
        "addedToBasketOverlay": "Ajouté au panier",
        "popupDiscountTextPart1": "Utiliser le code",
        "popupDiscountTextPart2": "appliquer la promotion",
        "addToBasketButtonTextOutOfStock": "Out of stock",
        "discountRibbonText": "De Réduction",
        "moreInfoProductSizesText": "Tailles disponibles",
        "chooseVoucher": "Choisissez votre bon dès maintenant",
        "photobookDiscountText": "Réduction Exclusive",
        "discount": "Réduction",
        "viewAllProducts": "Voir tous les produits",
        "selectGiftVoucher": "Sélectionnez un bon",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% SUR LE 2ème LIVRE PHOTO" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% sur votre second mug" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% sur votre seconde toile photo" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% sur votre second poster photo" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% sur votre seconde photo sur PVC" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% sur votre seconde photo sur aluminium" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% sur votre seconde photo sur plexiglas" 
            }
        },         
        "secondCopyPhotobookGiftIdea": "Idée cadeau",
        "secondCopyPhotobookGiftIdeaDescription": "Commandez un autre exemplaire de votre livre photo pour vos proches !",
        "discountedProductGiftIdeaDescription-Canvas": "Commandez une seconde toile photo identique pour vos proches!",
        "discountedProductGiftIdeaDescription-Poster": "Commandez un second poster photo identique pour vos proches!",
        "discountedProductGiftIdeaDescription-Aluminium": "Commandez une seconde photo sur aluminium pour vos proches!",
        "discountedProductGiftIdeaDescription-Forex": "Commandez une seconde photo sur PVC pour vos proches!",
        "discountedProductGiftIdeaDescription-Perspex": "Commandez une seconde photo sur plexiglas pour vos proches!",
        "discountedProductGiftIdeaDescription-Mug": "Commandez un second mug identique pour vos proches!",     
        "copyof": "Copie de",
        "editPhoto": "Edit photo", //needs translation
        "onlyVisibleToYou": "Visible uniquement par vous",
        "printsPopupText": "(We have selected photos suitable for photo printing)",
        "printsText": "Imprimez également vos photos sous forme de tirages ",
        "printsLink": "Voir mes tirages photo"
    },
    "es-ES": {
        "addToBasketButtonTextPrints": "Ir al editor",
        "addToBasketButtonText": "Añadir al carrito",
        "addToBasketButtonTextClicked": "Continuar al carrito",
        "priceDefaultText": "Precio",
        "pricePrintsDefaultText": "De",
        "discountDefaultText": "Usar código",
        "moreInfoDefaultText": "Vista grande",
        "moreInfoTryButtonText": "Prueba diferentes fotos",
        "bottomTextMoreInfo": "Personalizar",
        "bottomTextChangePhoto": "Cambiar foto",
        "bottomTextShowLargerImage": "Mostrar imagen más grande",
        "addedToBasketOverlay": "Añadido a la cesta",
        "popupDiscountTextPart1": "usar código",
        "popupDiscountTextPart2": "para recibir el descuento",
        "addToBasketButtonTextOutOfStock": "Agotado",
        "discountRibbonText": "Ahorrar",
        "moreInfoProductSizesText": "Selecciona el tamaño",
        "chooseVoucher": "Elige tu bono a continuación",
        "photobookDiscountText": "Descuento exclusivo",
        "discount": "Descuento",
        "viewAllProducts": "Ver todos los productos",
        "selectGiftVoucher": "Selecciona un bono regalo",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% de descuento en el 2º ÁLBUM DE FOTOS" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% de descuento en el 2º taza" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% de descuento en el 2º lienzo personalizado" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% de descuento en el 2º póster personalizado" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% de descuento en el 2º impresión en fórex" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% de descuento en el 2º impresión en aluminio" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% de descuento en el 2º impresión en metacrilato" 
            }
        }, 
        "secondCopyPhotobookGiftIdea": "Idea de regalo",
        "secondCopyPhotobookGiftIdeaDescription": "¡Consigue una copia de tu álbum de fotos para tus seres queridos!",
        "discountedProductGiftIdeaDescription-Canvas": "¡Consigue una copia de tu impresión en lienzo para tus seres queridos!",
        "discountedProductGiftIdeaDescription-Poster": "¡Obtenga una copia de la impresión de su póster para sus seres queridos!",
        "discountedProductGiftIdeaDescription-Aluminium": "¡Obtenga una copia de su impresión en aluminio para sus seres queridos!",
        "discountedProductGiftIdeaDescription-Forex": "¡Obtenga una copia de su impresión en forex para sus seres queridos!",
        "discountedProductGiftIdeaDescription-Perspex": "¡Obtenga una copia de su impresión acrílica para sus seres queridos!",
        "discountedProductGiftIdeaDescription-Mug": "¡Consigue una copia de tu taza para tus seres queridos!",        
        "copyof": "Copia de",
        "editPhoto": "Editar foto",
        "onlyVisibleToYou": "Sólo visible para ti",
        "printsPopupText": "(Hemos seleccionado fotografías adecuadas para la impresión fotográfica.)",
        "printsText": "Tus imágenes también están disponibles como impresiones fotográficas.",
        "printsLink": "Ver impresiones fotográficas"
    },
    "pt-PT": {
        "addToBasketButtonTextPrints": "Ir para o editor",
        "addToBasketButtonText": "Adicionar ao carrinho",
        "addToBasketButtonTextClicked": "Continuar no carrinho",
        "priceDefaultText": "Preço",
        "pricePrintsDefaultText": "De",
        "discountDefaultText": "Usar código",
        "moreInfoDefaultText": "Visualização ampliada",
        "moreInfoTryButtonText": "Experimente fotos diferentes",
        "bottomTextMoreInfo": "Personalizar",
        "bottomTextChangePhoto": "Alterar foto",
        "bottomTextShowLargerImage": "Mostrar imagem ampliada",
        "addedToBasketOverlay": "Adicionado ao carrinho",
        "popupDiscountTextPart1": "usar código",
        "popupDiscountTextPart2": "para receber o desconto",
        "addToBasketButtonTextOutOfStock": "Esgotado",
        "discountRibbonText": "Salvar",
        "moreInfoProductSizesText": "Selecionar tamanho",
        "chooseVoucher": "Escolha seu voucher abaixo",
        "photobookDiscountText": "Desconto Exclusivo",
        "discount": "Desconto",
        "viewAllProducts": "Ver todos os produtos",
        "selectGiftVoucher": "Selecione um vale-presente",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% desconto no 2º ÁLBUM DE FOTOS" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% desconto no 2º caneca" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% desconto no 2º telas personalizadas" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% desconto no 2º impressão de pósteres" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% desconto no 2º impressão em pvc" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% desconto no 2º impressão em alumínio" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% desconto no 2º impressão em acrílico" 
            }
        }, 
        "secondCopyPhotobookGiftIdea": "Ideia para presente",
        "secondCopyPhotobookGiftIdeaDescription": "Obtenha uma cópia do seu álbum de fotos para seus entes queridos!",
        "discountedProductGiftIdeaDescription-Canvas": "Obtenha uma cópia da sua impressão em tela para seus entes queridos!",
        "discountedProductGiftIdeaDescription-Poster": "Obtenha uma cópia do seu pôster para seus entes queridos!",
        "discountedProductGiftIdeaDescription-Aluminium": "Obtenha uma cópia de sua impressão em alumínio para seus entes queridos!",
        "discountedProductGiftIdeaDescription-Forex": "Obtenha uma cópia de sua impressão montada para seus entes queridos!",
        "discountedProductGiftIdeaDescription-Perspex": "Obtenha uma cópia de sua impressão em acrílico para seus entes queridos!",
        "discountedProductGiftIdeaDescription-Mug": "Obtenha uma cópia da sua caneca para seus entes queridos!",        
        "copyof": "Cópia de",
        "editPhoto": "Editar foto",
        "onlyVisibleToYou": "Visível apenas para você",
        "printsPopupText": "(Selecionamos fotos adequadas para impressão de fotos)",
        "printsText": "Suas fotos também estão disponíveis como impressão de fotos",
        "printsLink": "Ver impressões de fotos"
    },
    "da-DK": {
        "addToBasketButtonTextPrints": "Gå til editor",
        "addToBasketButtonText": "Læg ​​i kurv",
        "addToBasketButtonTextClicked": "Fortsæt til indkøbskurv",
        "priceDefaultText": "Pris",
        "pricePrintsDefaultText": "Fra",
        "discountDefaultText": "Brug kode",
        "moreInfoDefaultText": "Stor visning",
        "moreInfoTryButtonText": "Prøv forskellige billeder",
        "bottomTextMoreInfo": "Tilpas",
        "bottomTextChangePhoto": "Skift billede",
        "bottomTextShowLargerImage": "Vis større billede",
        "addedToBasketOverlay": "Føjet til kurv",
        "popupDiscountTextPart1": "brug kode",
        "popupDiscountTextPart2": "for at modtage rabatten",
        "addToBasketButtonTextOutOfStock": "Ikke på lager",
        "discountRibbonText": "Gem",
        "moreInfoProductSizesText": "Vælg størrelse",
        "chooseVoucher": "Vælg din voucher nedenfor",
        "photobookDiscountText": "Eksklusiv rabat",
        "discount": "Rabat",
        "viewAllProducts": "Se alle produkter",
        "selectGiftVoucher": "Vælg et gavekort",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% rabat på 2. FOTOBØGER" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% rabat på 2. krus" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% rabat på 2. foto på lærred" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% rabat på 2. fotoplakat" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% rabat på 2. foto på skumplade" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% rabat på 2. billede på aluminiumsplade" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% rabat på 2. billede på plexiglas" 
            }
        }, 
        "secondCopyPhotobookGiftIdea": "Gaveidé",
        "secondCopyPhotobookGiftIdeaDescription": "Få en kopi af din fotobog til dine kære!",
        "discountedProductGiftIdeaDescription-Canvas": "Få en kopi af dit lærredstryk til dine kære!",
        "discountedProductGiftIdeaDescription-Poster": "Få en kopi af dit plakattryk til dine kære!",
        "discountedProductGiftIdeaDescription-Aluminium": "Få en kopi af dit aluminiumstryk til dine kære!",
        "discountedProductGiftIdeaDescription-Forex": "Få en kopi af dit monterede print til dine kære!",
        "discountedProductGiftIdeaDescription-Perspex": "Få en kopi af dit akryltryk til dine kære!",
        "discountedProductGiftIdeaDescription-Mug": "Få en kopi af dit krus til dine kære!",        
        "copyof": "Kopi af",
        "editPhoto": "Rediger billede",
        "onlyVisibleToYou": "Kun synlig for dig",
        "printsPopupText": "(Vi har udvalgt fotos, der egner sig til fotoudskrivning)",
        "printsText": "Dine billeder er også tilgængelige som fotoprint",
        "printsLink": "Se fotoudskrifter"
    },
    "it-IT": {
        "addToBasketButtonTextPrints": "Vai all'editor",
        "addToBasketButtonText": "Aggiungi al carrello",
        "addToBasketButtonTextClicked": "Vai al carrello",
        "priceDefaultText": "Prezzo",
        "pricePrintsDefaultText": "Da",
        "discountDefaultText": "Usa il codice",
        "moreInfoDefaultText": "Vista ampia",
        "moreInfoTryButtonText": "Prova foto diverse",
        "bottomTextMoreInfo": "Personalizza",
        "bottomTextChangePhoto": "Cambia foto",
        "bottomTextShowLargerImage": "Mostra immagine più grande",
        "addedToBasketOverlay": "Aggiunto al carrello",
        "popupDiscountTextPart1": "usa il codice",
        "popupDiscountTextPart2": "per ricevere lo sconto",
        "addToBasketButtonTextOutOfStock": "Non disponibile",
        "discountRibbonText": "Salva",
        "moreInfoProductSizesText": "Seleziona taglia",
        "chooseVoucher": "Scegli il tuo buono qui sotto",
        "photobookDiscountText": "Sconto esclusivo",
        "discount": "Sconto",
        "viewAllProducts": "Visualizza tutti i prodotti",
        "selectGiftVoucher": "Seleziona un buono regalo",
        "secondCopyPhotobookDiscountText": {
            getDiscountText(discount) { 
                return discount + "% OFF 2° FOTOLIBRO" 
            }
        },
        "discountedProductDiscountText-Mug": {
            getDiscountText(discount) { 
                return discount + "% OFF 2° tazze" 
            }
        }, 
        "discountedProductDiscountText-Canvas": {
            getDiscountText(discount) { 
                return discount + "% OFF 2° stampa su tela" 
            }
        },
        "discountedProductDiscountText-Poster": {
            getDiscountText(discount) { 
                return discount + "% OFF 2° poster personalizzati" 
            }
        },
        "discountedProductDiscountText-Forex": {
            getDiscountText(discount) { 
                return discount + "% OFF 2° stampa su forex" 
            }
        },
        "discountedProductDiscountText-Aluminium": {
            getDiscountText(discount) { 
                return discount + "% OFF 2° stampa su alluminio" 
            }
        },
        "discountedProductDiscountText-Perspex": {
            getDiscountText(discount) { 
                return discount + "% OFF 2° stampa su acrilico" 
            }
        }, 
        "secondCopyPhotobookGiftIdea": "Idea regalo",
        "secondCopyPhotobookGiftIdeaDescription": "Ricevi una copia del tuo fotolibro per i tuoi cari!",
        "discountedProductGiftIdeaDescription-Canvas": "Ricevi una copia della tua stampa su tela per i tuoi cari!",
        "discountedProductGiftIdeaDescription-Poster": "Ricevi una copia del tuo poster stampato per i tuoi cari!",
        "discountedProductGiftIdeaDescription-Aluminium": "Ricevi una copia della tua stampa su alluminio per i tuoi cari!",
        "discountedProductGiftIdeaDescription-Forex": "Ricevi una copia della tua stampa montata per i tuoi cari!",
        "discountedProductGiftIdeaDescription-Perspex": "Ricevi una copia della tua stampa su acrilico per i tuoi cari!",
        "discountedProductGiftIdeaDescription-Mug": "Ricevi una copia della tua tazza per i tuoi cari!",        
        "copyof": "Copia di",
        "editPhoto": "Modifica foto",
        "onlyVisibleToYou": "Visibile solo a te",
        "printsPopupText": "(Abbiamo selezionato foto adatte per la stampa fotografica)",
        "printsText": "Le tue immagini sono disponibili anche come stampe fotografiche",
        "printsLink": "Visualizza stampe fotografiche"
    }
}

export default locale;